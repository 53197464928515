.drawer {
  --dialog-open-cubic-bezier: cubic-bezier(0, 1, 0.58, 1);
  --dialog-close-cubic-bezier: cubic-bezier(0.58, 0, 1, 1);
  --translate-y-start: 100vh;
  --translate-y-end: 0%;
  bottom: 0;
  top: auto;
  width: 100%;
  max-width: 100%;
  margin: 0 !important;
  padding: 0;
  position: fixed;
  overscroll-behavior: none;
  max-height: 75dvh;
  min-height: 10dvh;
  transition: height 0.1s ease-in-out;
  //box-shadow: var(--shadow-large);
  background: var(--geist-background);
  color: var(--geist-foreground);
  border: 1px solid var(--accents-2);
  border-radius: var(--geist-marketing-radius);

  overflow: hidden;
  overflow-y: auto;

  &::backdrop {
    margin: 0 !important;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    background-color: rgba(0, 0, 0, 0.7);
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
  }
}

.inner {
  outline: none;
  transition: padding-bottom 0.1s ease-in-out;
}
