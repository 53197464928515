.header_title {
  --color: var(--geist-foreground);
  --title-margin: var(--header-title-margin, var(--geist-space-large));
  border-bottom: 1px solid var(--accents-2);

  .inner {
    margin: var(--title-margin) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main {
    --direction: var(--lg-direction);
    --align: var(--lg-align);
    --justify: var(--lg-justify);
    --gap: var(--lg-gap);

    display: flex;
    flex-direction: var(--direction, column);
    align-items: var(--align, stretch);
    justify-content: var(--justify, flex-start);
    flex: var(--flex, initial);
    grid-gap: var(--gap, 0);
    gap: var(--gap, 0);
  }

  .back {
    color: var(--geist-secondary);
    transition: color 0.1s ease;
    font-size: 14px;
    margin-bottom: 2px;

    &:hover {
      color: var(--geist-foreground);
    }

    .back_icon {
      transform: scale(1.15);
      vertical-align: bottom;
      display: inline-flex;

      height: 1em;
      align-items: center;

      @media print {
        display: none;
      }
    }
  }

  h1 {
    font-weight: 500;
    font-family: var(--font-sans);
    margin: 0;
    color: var(--color);
    font-size: 2rem;
    letter-spacing: -0.049375rem;
    line-height: 2.5rem;

    em {
      font-style: normal;
      color: var(--geist-secondary);
    }
  }

  &.with_back h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  .actions {
    display: flex;
    gap: 10px;

    @media print {
      display: none;
    }
  }
}
