.input {
  display: flex;
  align-items: center;
  max-width: 100%;

  line-height: normal;
  font-size: var(--geist-form-font);
  height: var(--geist-form-height);

  input {
    font: inherit;
    width: 100%;
    min-width: 0;
    display: inline-flex;
    appearance: none;
    border: 1px solid var(--themed-border, var(--accents-2));
    border-radius: var(--geist-radius);
    padding: 0 var(--geist-gap-half);
    background: var(--themed-bg, var(--geist-background));
    color: var(--themed-fg, var(--geist-foreground));
    outline: none;
    transition: border-color 0.15s ease;
    -webkit-tap-highlight-color: transparent;

    font-size: inherit;
    height: inherit;
    line-height: inherit;
    text-align: inherit;

    &:focus {
      border-color: var(--accents-5);
    }

    &:read-only {
      border-color: transparent;
    }

    &:disabled {
      border-color: var(--accents-1);
    }

    @media only screen and (max-device-width: 780px) and (-webkit-min-device-pixel-ratio: 0) {
      font-size: 16px;
      line-height: 1;
    }
  }

  &.with_prefix {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.no_prefix_styling {
    input {
      border-left: 0;
    }
  }

  &.with_suffix {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.no_suffix_styling {
    input {
      border-right: 0;
      padding-right: 0;
    }
  }

  &.small {
    font-size: var(--geist-form-small-font);
    height: var(--geist-form-small-height);
    line-height: var(--geist-form-small-line-height);
  }

  &.medium {
    font-size: var(--geist-form-medium-font);
    height: var(--geist-form-medium-height);
    line-height: var(--geist-form-medium-line-height);
  }

  &.large {
    height: var(--geist-form-large-height);
    font-size: var(--geist-form-large-font);
    line-height: var(--geist-form-large-line-height);
  }

  .prefix,
  .suffix {
    flex-shrink: 0;
    position: relative;
    color: var(--accents-4);
    background: var(--accents-1);
    border: 1px solid var(--accents-2);
    height: 100%;
    padding: 0 var(--geist-gap-half);
    display: flex;
    align-items: center;
    transition: border-color 0.15s ease, color 0.15s ease;
    //z-index: -1;

    &.no_styling {
      background: var(--themed-bg, var(--geist-background));
    }
  }

  .prefix {
    border-right: 0;
    border-radius: var(--geist-radius) 0 0 var(--geist-radius);
  }

  &.focus.no_prefix_styling .prefix {
    border-color: var(--accents-5);
  }

  &.readonly .prefix {
    border: none;
  }

  .suffix {
    border-left: 0;
    border-radius: 0 var(--geist-radius) var(--geist-radius) 0;
  }

  &.focus.no_suffix_styling .suffix {
    border-color: var(--accents-5);
  }

  &.readonly .suffix {
    border: none;
  }
}

@media (max-width: 600px) {
  .input {
    font-size: 16px;

    &.small {
      font-size: 16px;
    }

    &.medium {
      font-size: 16px;
    }

    &.large {
      font-size: var(--geist-form-large-font);
    }
  }
}
