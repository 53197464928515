.modal_actions {
  position: sticky;
  bottom: 0;
  border-top: 1px solid var(--accents-2);
  display: flex;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
}
