.popover_item {
  display: flex;

  max-width: 200px;
  padding: 8px 20px;

  position: relative;
  font-size: 14px;
  color: var(--accents-5);
  line-height: 20px;
  transition: color 0.1s ease, background-color 0.1s ease;

  &:hover {
    color: var(--geist-foreground);
  }

  &.active {
    color: var(--geist-foreground);
  }

  a {
    width: calc(100% + 40px);
    margin: -8px -20px;
    padding: 8px 20px;
  }
}
