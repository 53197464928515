.popover {
  display: inline-flex;
  position: relative;
  height: fit-content;

  &.open .trigger {
    &:before {
      content: "";
      background: transparent;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 80;
    }
  }

  .overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 100;

    .inner {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.open {
    .overlay {
      display: initial;
    }
  }
}
