.menu_item {
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 var(--geist-space-gap-quarter);
  height: 36px;
  border-radius: var(--geist-radius);
  color: var(--accents-7);

  &[data-selected] {
    color: var(--geist-foreground);
    background: var(--accents-2);
  }

  @media (max-width: 600px) {
    height: var(--geist-form-large-height);
    font-size: 1rem;
  }

  &[aria-disabled] {
    cursor: default;
    color: var(--accents-3);
    pointer-events: none;
  }
}

.error {
  color: var(--geist-error);

  &:hover {
    color: var(--geist-error);
  }

  &[data-selected] {
    color: var(--geist-error);
  }
}

.prefix {
  display: flex;
  margin-right: var(--geist-gap-quarter);
}

.suffix {
  display: flex;
  margin-left: auto;
  padding-left: var(--geist-gap-half);
}
