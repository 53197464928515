.container {
  max-width: 100%;
  //margin-left: auto;
  //margin-right: auto;
  width: var(--geist-page-width-with-margin);
  padding-left: var(--geist-page-margin);
  padding-right: var(--geist-page-margin);

  &.full_width {
    width: unset;
  }
}
