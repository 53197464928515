.modal_action {
  color: var(--accents-5);
  background: var(--geist-background);
  font-family: var(--font-sans);
  border: none;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: var(--geist-gap) 0;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:only-child):not(:last-child) {
    border-right: 1px solid var(--accents-2);
  }

  &:not([disabled]):hover {
    background: var(--accents-1);
    color: var(--geist-foreground);
  }
}
