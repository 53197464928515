.header_dashboard {
  display: flex;
  align-items: center;
  margin: auto;
  padding: 0 var(--geist-page-margin);
  height: var(--header-height);
  -webkit-user-select: none;
  user-select: none;
  position: relative;

  @media print {
    display: none;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 30px;
}

.logo {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.35px;

  @media (max-width: 600px) {
    display: none;
  }
}

.company {
}

.right {
  margin-left: auto;
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
  min-width: 0;

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;

  .full_name {
    @media (max-width: 600px) {
      display: none;
    }
  }
}
