.base {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: none;

  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.button {
  border-radius: var(--geist-radius);
  color: var(--themed-fg, var(--geist-background));
  --color: var(--themed-fg, var(--geist-background));
  background: var(--themed-bg, var(--geist-foreground));
  border: 1px solid var(--themed-border, var(--geist-foreground));
  font-weight: 500;
  padding: 0 var(--geist-gap-half);
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition-property: border-color, background, color, transform, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  font-size: var(--geist-form-font);
  line-height: var(--geist-form-line-height);
  height: var(--geist-form-height);

  @media (hover: hover) {
    &:not(.disabled):hover {
      border: 1px solid var(--geist-foreground);
      color: var(--geist-foreground);
      background-color: var(--geist-background);
    }
  }

  &.svg_only {
    width: var(--geist-form-height);
    padding: 0;
    display: flex;
    align-items: center;
  }

  .loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    background: var(--themed-bg, var(--geist-foreground));
    border-radius: var(--geist-radius);

    & > * {
      margin: 0;
    }
  }

  &.secondary {
    --themed-bg: var(--geist-background);
    --themed-fg: var(--geist-secondary);
    --themed-border: var(--accents-2);

    @media (hover: hover) {
      &:not(.disabled):hover {
        border: 1px solid var(--geist-foreground);
      }
    }
  }

  &.error {
    --themed-fg: #fff;
    --themed-bg: var(--geist-error);
    --themed-border: var(--geist-error);

    @media (hover: hover) {
      &:not(.disabled):hover {
        background: transparent;
        color: var(--themed-bg);
        border: 1px solid var(--themed-bg);
      }
    }
  }

  &.small {
    font-size: var(--geist-form-small-font);
    height: var(--geist-form-small-height);
    line-height: var(--geist-form-small-line-height);

    &.svg_only {
      width: var(--geist-form-small-height);
    }
  }

  &.medium {
    font-size: var(--geist-form-font);
    line-height: var(--geist-form-line-height);
    height: var(--geist-form-height);

    &.svg_only {
      width: var(--geist-form-height);
    }
  }

  &.large {
    height: var(--geist-form-large-height);
    font-size: var(--geist-form-large-font);
    line-height: var(--geist-form-large-line-height);

    &.svg_only {
      width: var(--geist-form-large-height);
    }
  }

  &.loading {
    pointer-events: none;
  }

  &.ghost {
    background: none;
    border-color: transparent;
    color: var(--themed-bg);
    --lighten-color: hsla(0, 0%, 100%, 0.8);

    @media (hover: hover) {
      &:not(.disabled):hover {
        background-color: var(--accents-2);
      }
    }
  }

  &.disabled {
    background: var(--accents-1);
    color: var(--accents-3);
    border-color: var(--accents-2);
    cursor: not-allowed;
  }

  &:focus-visible {
    box-shadow: 0 0 0 1px var(--geist-background), 0 0 0 3px var(--themed-bg, var(--geist-foreground));
    outline: none;
  }
}
