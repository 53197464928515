.popover_menu {
  color: var(--geist-foreground);
  display: inline-block;
  text-align: left;
  background: var(--geist-background);
  max-width: 100vw;
  box-shadow: var(--shadow-medium);
  border-radius: 5px;

  margin-top: 11px;
  //width: 150px;
  min-width: auto;
  padding: 8px 0;
  overflow: visible;

  a {
    margin: -8px -20px;
    padding: 8px 20px;

    color: currentColor;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
}
