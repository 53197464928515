.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: auto;
  z-index: 1001;
  background-color: rgba(0, 0, 0, var(--portal-opacity));
}

.wrapper {
  max-height: 100vh;
  overflow-y: auto;

  background-color: var(--geist-background);
  border-radius: 8px;
  box-shadow: var(--shadow-large);
  color: var(--geist-foreground);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1), transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);

  animation: init 0.35s cubic-bezier(0.4, 0, 0.2, 1);

  @keyframes init {
    from {
      opacity: 0;
      transform: translate3d(0, -40px, 0);
    }

    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
}
