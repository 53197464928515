.modal_form_item {
  display: flex;
  gap: var(--geist-gap-half);
  align-items: baseline;
  flex-shrink: 0;

  .label {
    width: var(--form-item-label-width, 100px);
    min-width: var(--form-item-label-width, 100px);

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: var(--geist-secondary);
  }

  & > :not(.label) {
    flex: 1;
  }
}

.modal_form_item + .modal_form_item {
  margin-top: var(--geist-space-2x);
}
