.avatar {
  width: var(--avatar-size, 32px);
  height: var(--avatar-size, 32px);
  font-size: calc(var(--avatar-size, 32px) / 2);
  flex-shrink: 0;
  border-radius: 100%;
  border: 1px solid var(--accents-2);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 100%;
    object-fit: cover;
  }
}
