.tooltip {
  list-style-type: none;
  margin: 0;
  box-shadow: var(--shadow-medium);
  border-radius: var(--geist-radius);
  font-size: 0.875rem;
  line-height: 1.5;

  max-width: 250px;
  text-align: center;

  outline: none;
  background: var(--geist-foreground);
  border: 1px solid var(--border-color);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: var(--geist-space-2x) var(--geist-gap-half);
  color: var(--geist-background);

  @media (max-width: 600px) {
    width: unset !important;
  }

  &.no_border {
    border: none;
  }
}

:global(.dark-theme) .tooltip {
  box-shadow: none;
}

.wrapper {
  --border-color: var(--accents-2);
  z-index: 10000;
  animation: show 0.5s;

  @keyframes show {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.arrow {
  --triangle-color: var(--geist-foreground);
  position: absolute;
  width: 12px;
  height: 12px;

  @media (max-width: 600px) {
    display: none;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  &:before {
    border: 8px solid transparent;
    border-bottom: 8px solid var(--border-color);
  }

  &:after {
    border: 6px solid transparent;
    border-bottom-color: #900fff;
  }
}

[data-popper-placement^="bottom"] .arrow {
  top: -11px;

  &:before {
    border-color: transparent;
    border-bottom-color: var(--border-color);
    left: -2px;
    bottom: 0;
  }

  &:after {
    border-color: transparent;
    border-bottom-color: var(--triangle-color);
  }
}

[data-popper-placement^="top"] .arrow {
  bottom: -11px;

  &:before {
    border-color: transparent;
    border-top-color: var(--border-color);
    left: -2px;
  }

  &:after {
    border-color: transparent;
    border-top-color: var(--triangle-color);
  }
}

[data-popper-placement^="left"] .arrow {
  right: -11px;

  &:before {
    border-color: transparent;
    border-left-color: var(--border-color);
    top: -2px;
  }

  &:after {
    border-color: transparent;
    border-left-color: var(--triangle-color);
  }
}

[data-popper-placement^="right"] .arrow {
  left: -11px;

  &:before {
    border-color: transparent;
    border-right-color: var(--border-color);
    top: -2px;
    right: 0;
  }

  &:after {
    border-color: transparent;
    border-right-color: var(--triangle-color);
  }
}
