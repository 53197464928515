.container {
  --themed-fg: var(--geist-foreground);
  --themed-bg: var(--geist-background);
  --themed-border: var(--accents-2);

  position: relative;
  width: fit-content;
}

.select {
  cursor: pointer;
  font: inherit;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--geist-form-font);
  height: var(--geist-form-height);
  line-height: var(--geist-form-line-height);
  text-rendering: auto;
  width: 100%;
  border-radius: var(--geist-radius);
  border: 1px solid var(--themed-border);
  background: var(--themed-bg);
  color: var(--themed-fg);
  padding: 0 var(--geist-gap-half);
  padding-right: calc(1.5 * var(--geist-gap));
  transition: border-color 0.15s ease;
  outline-color: var(--geist-foreground);

  &.read_only {
    border-color: transparent;
  }

  &:disabled {
    border-color: var(--accents-2);
    cursor: not-allowed;
  }

  &.empty {
    color: var(--geist-secondary);
  }

  &.small {
    font-size: var(--geist-form-small-font);
    height: var(--geist-form-small-height);
    line-height: var(--geist-form-small-line-height);
  }

  &.medium {
    font-size: var(--geist-form-medium-font);
    height: var(--geist-form-medium-height);
    line-height: var(--geist-form-medium-line-height);
  }

  &.large {
    height: var(--geist-form-large-height);
    font-size: var(--geist-form-large-font);
    line-height: var(--geist-form-large-line-height);
  }
}

.prefix,
.suffix {
  display: inline-flex;
  position: absolute;
  pointer-events: none;
  color: var(--accents-5);
  transition: color 0.15s ease;
  height: 100%;
  align-items: center;
  top: 0;
  justify-content: center;
}

.suffix {
  right: var(--geist-gap-half);
}

.select.read_only + .suffix {
  display: none;
}

.select:disabled + .suffix {
  opacity: 0.5;
}

.label {
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--geist-secondary);
  margin-bottom: var(--geist-space-2x);
  max-width: 100%;
  cursor: text;
  text-transform: capitalize;
}

.empty_option {
  color: var(--geist-secondary);
}
