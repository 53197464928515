.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: var(--accents-7);
  list-style-type: none;
  border-radius: var(--geist-radius);
  user-select: none;
  min-width: 222px;

  .icon {
    width: 20px;
    height: 20px;
    opacity: 0;
  }

  &.active {
    font-weight: 500;

    .icon {
      opacity: 1;
    }
  }

  .name {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
    min-width: 0;
  }
}

@media (max-width: 600px) {
  .item {
    font-size: 16px;
    text-overflow: ellipsis;
  }
}
