.company_switcher {
  display: flex;
  align-items: center;
  left: 0;
  position: relative;
  color: var(--geist-foreground);

  .company {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .name {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
    min-width: 0;

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  .button {
    margin-left: 4px;
    flex-shrink: 0;
    padding: 0px 8px;
    line-height: 0;
    height: var(--geist-form-height);
    display: flex;
    align-items: center;
    border-radius: var(--geist-radius);

    transition-property: border-color, background, color, transform, box-shadow;
    transition-duration: 0.15s;

    &:hover {
      background-color: var(--accents-2);
    }
  }

  .overlay {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 80;
  }

  .menu {
    position: absolute;
    bottom: -4px;
    left: 0;

    background: red;

    transform: translateY(100%);

    padding: 8px;

    box-shadow: var(--shadow-medium);
    background: var(--geist-background);
    border-radius: var(--geist-radius);
    border: 1px solid var(--accents-2);
    overflow: hidden;
    min-width: 240px;
    animation: scope_open__O6KtU 0.1s ease forwards;
    z-index: 100;
  }
}
